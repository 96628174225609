<template lang="pug">
v-container.px-20.px-sm-5
  .d-flex.mb-3
    v-card.pa-3(:style="{background: '#' + team1.team.primaryColor}" width="50%")
      h3.text-center(:style="{color: '#' + team1.team.primaryFGColor}") {{ team1.team.abbreviation }}
    v-card.pa-3(:style="{background: '#' + team2.team.primaryColor}" width="50%")
      h3.text-center(:style="{color: '#' + team2.team.primaryFGColor}") {{ team2.team.abbreviation }}
  .d-flex.mb-3
    v-card(outlined elevation="0" color="transparent" width="50%")
      span
      v-avatar.ml-1(v-for='(r, index) in result1.recentMatches' :key="index" :color="r == 'L'? 'red': 'green'" size="25")
        span(class="white--text") {{r}}
      span
    v-card.text-right(outlined elevation="0" color="transparent" width="50%")
      span
      v-avatar.ml-1(v-for='(r, index) in result2.recentMatches' :key="index" :color="r == 'L'? 'red': 'green'" size="25")
        span(class="white--text") {{r}}
      span
  .d-flex.mb-3
    v-card.text-right(outlined elevation="0" color="transparent" width="40%")
      span {{ result1.totalWin }}
    v-card(outlined elevation="0" color="transparent" width="20%")
      v-img.mx-auto(max-height="40" max-width="40" :src="require('../../assets/svg/Win_Large.svg')" alt="Wins")
    v-card.text-left(outlined elevation="0" color="transparent" width="40%")
      span {{ result2.totalWin }}
  .d-flex.mb-3
    v-card.text-right(outlined elevation="0" color="transparent" width="40%")
      span {{ getPlayer(team1.captainId, team1.players)[0].fullName}}
    v-card(outlined elevation="0" color="transparent" width="20%")
      v-img.mx-auto(max-height="30" max-width="30" :src="require('../../assets/svg/Captain.svg')" alt="Captain")
    v-card.text-left(outlined elevation="0" color="transparent" width="40%")
      span {{ getPlayer(team2.captainId, team2.players)[0].fullName}}
  v-tabs(v-model='tab' centered)
    v-tabs-slider(color='grey')
    v-tab(v-for='item in statsModes' :key='item.name')
      | {{ item.title }}
  v-tabs-items(v-model='tab')
    v-tab-item(v-for='item in statsModes' :key='item.name')
      div 
        InfoItem
          template(v-slot:title)
            v-img.mx-auto(max-height="60" max-width="60" :src="require('../../assets/svg/Bat_Large.svg')" alt="Batsman")
          template(v-slot:team1)
            PlayerInfo(:playerStats="getPlayerStats(team1.batsmanIds, team1.players, item.name)" :mode="item" playerType = "batsman")
          template(v-slot:team2)
            PlayerInfo(:playerStats="getPlayerStats(team2.batsmanIds, team2.players, item.name)" :mode="item" playerType = "batsman")
        InfoItem
          template(v-slot:title)
            v-img.mx-auto(max-height="60" max-width="60" :src="require('../../assets/svg/AllRounder.svg')" alt="All Rounder")
          template(v-slot:team1)
            PlayerInfo(:playerStats="getPlayerStats(team1.allrounderIds, team1.players, item.name)" :mode="item" playerType = "allrounder")
          template(v-slot:team2)
            PlayerInfo(:playerStats="getPlayerStats(team2.allrounderIds, team2.players, item.name)" :mode="item" playerType = "allrounder")
        InfoItem
          template(v-slot:title)
            v-img.mx-auto(max-height="60" max-width="60" :src="require('../../assets/svg/Ball_Large.svg')" alt="Bowler")
          template(v-slot:team1)
            PlayerInfo(:playerStats="getPlayerStats(team1.bowlerIds, team1.players, item.name)" :mode="item" playerType = "bowler")
          template(v-slot:team2)
            PlayerInfo(:playerStats="getPlayerStats(team2.bowlerIds, team2.players, item.name)" :mode="item" playerType = "bowler")
</template>

<script>
import InfoItem from '@/components/MatchInfo/InfoItem'
import PlayerInfo from '@/components/MatchInfo/PlayerInfo'
export default {
  name: 'MatchInfo',
  components: {
    InfoItem,
    PlayerInfo
  },
  data() {
    return {
      tab: null,
      statsModes: [
        {
          name: 'pstats',
          title: '2021 IPL form'
        },
        {
          name: 'h2hstats',
          title: 'Head - head'
        }
      ]
    }
  },
  props: {
    match: {
      required: true,
      type: Object
    },
  },
  computed: {
    team1() {
      return this.match.teams[0]
    },
    team2() {
      return this.match.teams[1]
    },
    result1() {
      return this.match.results.find(r => r.abbreviation === this.match.teams[0].team.abbreviation)
    },
    result2() {
      return this.match.results.find(r => r.abbreviation === this.match.teams[1].team.abbreviation)
    }
  },
  methods: {
    getPlayer(id, players) {
      return players.filter(p => p.id == id)
    },
    getPlayerStats(ids, players, statsMode) {
      let playerStats = []
      ids.forEach(id => {
        playerStats.push(
          {
            ...players.find(p => p.id == id),
            stats: this.match[statsMode].filter(p => p.playerId == id || p.id == id)
          }
        )
      })
      return playerStats
    }
  }
}
</script>