<template lang="pug">
v-footer(padless='padless')
  v-card.text-center(:color="($vuetify.theme.dark) ? themeColor + ' darken-4' : themeColor + ' accent-4'" flat='' tile='' width='100%')
    v-card-text
      v-btn.mx-4(v-for='link in links' :key='link.title' icon='' :href="link.route" :target="link.target")
        v-icon(size='24px')
          | {{ link.icon }} 
    v-divider
    v-card-text.white--text
      | {{ new Date().getFullYear() }} &mdash; 
      span.font-weight-light Cric
      span Krack
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      links :[
        {
          route: "/",
          title: "Home",
          icon: "mdi-home"
        },
        {
          route: "mailto:crickrackerz@gmail.com?subject=Email Us - CricKrack",
          title: "Email",
          icon: "mdi-email"
        },
        {
          route: "https://www.facebook.com/crickrackerz/",
          title: "Facebook",
          icon: "mdi-facebook",
          target: "_blank"
        },
        {
          route: "https://twitter.com/crickrackerz",
          title: "titter",
          icon: "mdi-twitter",
          target: "_blank"
        },
        {
          route: "https://www.instagram.com/crickrackerz/",
          title: "Instagram",
          icon: "mdi-instagram",
          target: "_blank"
        },
      ]
    }
  },
  computed: {
    ...mapState(['themeColor'])
  },
}
</script>