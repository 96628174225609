<template lang="pug">
div
  div
    v-container
      div(v-if='this.leaderboard[0]')
        v-tabs(v-model='tab' centered)
          v-tabs-slider(color='blue')
          v-tab(v-for='item in items' :key='item.name')
            | {{ item.name }}
        v-tabs-items(v-model='tab')
          v-tab-item(v-for='item in items' :key='item.name')
            v-container(v-if="item.name == 'Team'")
              v-card.mx-auto.my-1(:outlined='!$vuetify.breakpoint.xsOnly' elevation="0")
                v-row(align = "center")
                  v-col(class="d-none d-sm-block")
                    v-card(outlined elevation="0" color="transparent")
                      v-img.mx-auto.mt-4(max-height="80" max-width="80" :src="require('../assets/svg/Win_Large.svg')" alt="Wins")
                      v-card-text.text-center()
                        div.text-overline Teams
                  v-col(v-for="(item, index) in topTeams" :key="index")
                    v-card.mx-auto.my-1(outlined elevation="0" color="transparent")
                      v-avatar(tile size="100" :style="{background: '#' + getTeam(item.abbreviation).primaryColor}") 
                        span(:style="{color: '#' + getTeam(item.abbreviation).primaryFGColor}") {{item.abbreviation}}
                      div.text-overline.mx-2 {{item.points}} Points
                v-card-actions.justify-end
                  v-btn(text href="/leaderboard?tab=0") Full List

            v-container(v-if="item.name == 'Batting'")
              v-card.mx-auto.my-1(:outlined='!$vuetify.breakpoint.xsOnly' elevation="0")
                v-row(align = "center")
                  v-col(class="d-none d-sm-block")
                    v-card(outlined elevation="0" color="transparent")
                      v-img.mx-auto.mt-4(max-height="80" max-width="80" :src="require('../assets/svg/Bat_Large.svg')" alt="Batsman")
                      v-card-text.text-center
                        div.text-overline Batting
                  v-col(v-for="(item, index) in topBatting" :key="index")
                    v-card.mx-auto.my-1(outlined elevation="0" color="transparent")
                      v-avatar(tile size="100" :style="{background: '#' + getTeam(item.team_abbr).primaryColor}") 
                        span(:style="{color: '#' + getTeam(item.team_abbr).primaryFGColor}") {{item.shortName}}
                      div.text-overline.mx-2 {{item.r}} Runs
                v-card-actions.justify-end
                  v-btn(text href="/leaderboard?tab=1") Full List

            v-container(v-if="item.name == 'Bowling'")
              v-card.mx-auto.my-1(:outlined='!$vuetify.breakpoint.xsOnly' elevation="0")  
                v-row(align = "center")
                  v-col(class="d-none d-sm-block")
                    v-card(outlined elevation="0" color="transparent")
                      v-img.mx-auto.mt-4(max-height="80" max-width="80" :src="require('../assets/svg/Ball_Large.svg')" alt="Bowler")
                      v-card-text.text-center
                        div.text-overline Bowling
                  v-col(v-for="(item, index) in topBowling" :key="index")
                    v-card.mx-auto.my-1(outlined elevation="0" color="transparent")
                      v-avatar(tile size="100" :style="{background: '#' + getTeam(item.team_abbr).primaryColor}") 
                        span(:style="{color: '#' + getTeam(item.team_abbr).primaryFGColor}") {{item.shortName}}
                      div.text-overline.mx-2 {{item.w}} Wkts             
                v-card-actions.justify-end
                  v-btn(text href="/leaderboard?tab=2") Full List   
  div 
    MatchStats(:matchstats="lastPlayedMatchStats")
    v-container.text-right(v-if='this.leaderboard.length > 0')
      router-link.text-decoration-none.mr-2(to="statistics")
        span.text-caption Statistics
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MatchStats from '@/components/MatchStats'
import moment from 'moment'
export default {
  name: 'Home',
  components: {
    MatchStats
  },
  data () {
    return {
      tab: null,
      "items": [
        {"name": "Team", "imageUrl": "Win_Large.svg"},
        {"name": "Batting", "imageUrl": "Bat_Large.svg"},
        {"name": "Bowling", "imageUrl": "Ball_Large.svg"}
      ]
    }
  },
  computed: {
    ...mapState(['leaderboard', 'lastPlayedMatchStats', 'squads']),
    teams() {
      let teams = {}
      this.squads.forEach(s => {
        teams[s.team.abbreviation] = s.team
      })
      return teams
    },
    topTeams() {
      if (this.leaderboard[0]) {
        let teams = this.leaderboard[0].teams.map(i => i).sort((team1, team2) => team2.points - team1.points)
        return teams.splice(0, 3)
      }
      return []
    },
    topBowling() {
      if (this.leaderboard[0]) {
        let teams = this.leaderboard[0].bowling.map(i => i).sort((item1, item2) => item2.w - item1.w)
        return teams.splice(0, 3)
      }
      return []
    },
     topBatting() {
      if (this.leaderboard[0]) {
        let teams = this.leaderboard[0].batting.map(i => i).sort((item1, item2) => item2.r - item1.r)
        return teams.splice(0, 3)
      }
      return []
    }
  },
  mounted() {
    this.getLeaderboard()
    this.getLastPlayedMatchStats()
    this.getSquads()
  },
  methods: {
    ...mapActions(['getLeaderboard', 'getLastPlayedMatchStats', 'getSquads']),
    getTeam(abbr) {
      return this.teams[abbr] || {}
    } 
  }
}
</script>