<template lang="pug">
div.mb-4
  v-breadcrumbs(:items="breadcrumbs()" divider="/")
  div.ml-6(v-for='link in links' :key='link.text')
    router-link.text-decoration-none.mr-2(:to="link.href")
      span.text-caption {{link.text}}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'Matches',
  metaInfo() {
    return { 
      title: 'Cricket Match Directory | Find IPL Cricket Prediction Online | CricKrack',
        meta: [
          { name: 'description', content: 'Match Directory for IPL at CricKrack.'},
          { property: 'og:title', content: 'Cricket Match Directory | Find IPL Cricket Prediction Online | CricKrack'},
          { property: 'og:site_name', content: 'CricKrack'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'noindex,follow'} 
        ]
    }
  },
  components: {
    
  },
  data () {
    return {
      tab: null
    }
  },
  computed: {
    ...mapState(['matches']),
    links() {
      let matches = this.matches
      matches.sort((a, b) => 
        new Date(a.date.seconds * 1000).getTime() - new Date(b.date.seconds * 1000).getTime()
      )
      matches = matches.filter(match => match.stats_available)
      let links = []
      matches .forEach(m => {
        let link = {}
        link.href = this.getUrl(m)
        link.text = this.getText(m)
        links.push(link)
      })
      return links
    },
  },
  mounted() {
    this.getMatches()
  },
  methods: {
    ...mapActions(['getMatches']),
    breadcrumbs() {
      let items = [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'IPL',
          disabled: true,
          href: '#',
        }
      ]
      return items
    },
    getUrl(match) {
      let mdate = new Date(match.date.seconds * 1000)
      return '/matches/ipl-' + moment(mdate).format('YYYYMMDD') + '-' + match.team1_abbr.toLowerCase() + '-vs-' +  match.team2_abbr.toLowerCase()
    },
    getText(match) {
      let mdate = new Date(match.date.seconds * 1000)
      return  moment(mdate).format('MM/DD/YYYY') + ' / ' +  match.team1_abbr + ' vs ' + match.team2_abbr
    }
  }
}
</script>