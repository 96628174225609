<template lang="pug">
  div
    v-tabs(v-model='tab' centered)
      v-tabs-slider(color='blue')
      v-tab(v-for='item in matchstats' :key='item.match_no')
        | {{ item.teams[0].team.shortName }} - {{ item.teams[1].team.shortName }}
    v-tabs-items(v-model='tab')
      v-tab-item(v-for='item in matchstats' :key='item.match_no')
        MatchInfo(:match="item") 
</template>

<script>
import MatchInfo from '@/components/MatchInfo/MatchInfo'
export default {
  name: 'MatchStats',
  components: {
    MatchInfo
  },
  data() {
    return {
      tab: null,
    }
  },
  props: {
    matchstats: {
      required: true,
      type: Array
    },
  }
}
</script>