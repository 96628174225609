import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '../db'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    matches: [],
    squads: [],
    matchstats: [],
    lastPlayedMatchStats: [],
    recentPlayedMatchStats: [],
    leaderboard: [],
    themeColor: localStorage.getItem('themeColor') || 'light-blue'
  },
  mutations: {
    ...vuexfireMutations,
    setThemeColor(state: any, value) {
      state.themeColor = value
    },
    setLastPlayedMatchStats(state, mstats) {
      state.lastPlayedMatchStats = mstats
    },
    setRecentPlayedMatchStats(state, mstats) {
      state.recentPlayedMatchStats = mstats
    }
  },
  actions: {
    getMatches: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('matches', db.collection('matches'), { reset: false })
    }),
    getSquads: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('squads', db.collection('squads'), { reset: false })
    }),
    getMatchStats: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('matchstats', db.collection('matchstats'), { reset: false })
    }),
    getLeaderboard: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('leaderboard', db.collection('leaderboard'), { reset: false })
    }),
    getLastPlayedMatchStats(context) {
      db.collection('matches').where('stats_available', '==', true)
        .orderBy('date', 'desc').limit(1).get()
        .then(rs => rs.docs.map(doc => doc.data()))
        .then(matches => { 
          return db.collection('matchstats').where('matchDate', '==', matches[0].date).get()
        })
        .then(rs => rs.docs.map(doc => doc.data()))
        .then(matchstats => {
            context.commit('setLastPlayedMatchStats', matchstats)
        })
    },
    getRecentPlayedMatchStats(context) {
      db.collection('matches').where('stats_available', '==', true)
        .orderBy('date', 'desc').get()
        .then(rs => rs.docs.map(doc => doc.data()))
        .then(matches => { 
          // get stats for last 10 days
          let dates: any[] = []
          const lookup = new Set();
          matches.forEach(match => {
            if (!lookup.has(match.date.seconds)) { 
              lookup.add(match.date.seconds);
              dates.push(match.date)
            }
          })
          dates = dates.slice(0, 10)
          const promises:any[] = [];  
          dates.forEach(d => {
            promises.push(db.collection('matchstats').where('matchDate', '==', d).get());
          });
          return Promise.all(promises);
        })
        .then(ms_rss => {
          let mstats:any[] = [];
          ms_rss.forEach(rs => mstats = mstats.concat(rs.docs.map((doc: any) => doc.data())));
          context.commit('setRecentPlayedMatchStats', mstats)
        })
    },
    updateThemeColor({ commit }, value) {
      localStorage.setItem('themeColor', value)
      commit('setThemeColor', value)
    },
  },
  modules: {
  }
})
