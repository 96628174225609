import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Statistics from '@/views/Statistics.vue'
import Leaderboard from '@/views/Leaderboard.vue'
import About from '@/views/About.vue'
import Match from '@/views/Match.vue'
import Matches from '@/views/Matches.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard
  },
  {
    path: '/matches',
    name: 'Matches',
    component: Matches
  },
  {
    path: '/matches/ipl-:date([0-9]{8})-:x(\\D+)-vs-:y(\\D+)',
    name: 'Match',
    component: Match
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
