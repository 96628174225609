














import Vue from 'vue'
export default Vue.extend({
  name: 'CricKrack',
  metaInfo() {
    return { 
      title: 'About Us | Find IPL Cricket Prediction Online | CricKrack',
        meta: [
          { name: 'description', content: 'Checkout latest Cricket statistics ✓ Prediction ✓ Batting ✓ Bowling ✓ Team Records at CricKrack.'},
          { property: 'og:title', content: 'Cricket Statistics - Find IPL Cricket Stats Online | IPL20 Today Match Prediction | CricKrack'},
          { property: 'og:site_name', content: 'CricKrack'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'index,follow'} 
        ]
    }
  },
});
