<template lang="pug">
div
  v-breadcrumbs(v-if="mstats.length > 0" :items="breadcrumbs(mstats)" divider="/")
  MatchStats(:matchstats="mstats")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MatchStats from '@/components/MatchStats'
import moment from 'moment'
export default {
  name: 'Match',
  metaInfo() {
    if(this.mstats.length > 0) {
      return { 
      title: this.getPageTitle(this.mstats) + " | Dream11 IPL Team Prediction | Fantasy Cricket | CricKrack",
        meta: [
          { name: 'description', content: 'IPL - ' + this.getPageTitle(this.mstats) + " | Dream11 IPL Team Prediction | Fantasy Cricket | CricKrack",},
          { property: 'og:title', content: this.getPageTitle(this.mstats) + " | Dream11 IPL Team Prediction | Fantasy Cricket | CricKrack"},
          { property: 'og:site_name', content: 'CricKrack'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'index,follow'} 
        ]
     }
    }
  },
  components: {
    MatchStats
  },
  data () {
    return {
      tab: null
    }
  },
  computed: {
    ...mapState(['matchstats']),
    mstats() {
      let date = this.$route.params.date
      let teamA = this.$route.params.x
      let teamB = this.$route.params.y
      let matchstats = this.matchstats
      matchstats = matchstats.filter(match => {
        let mdate = new Date(match.matchDate.seconds * 1000)
        return moment(mdate).format('MM/DD/YYYY') === moment(date, "YYYYMMDD").format('MM/DD/YYYY') 
          && [teamA, teamB].includes(match.teams[0].team.abbreviation.toLowerCase())
          && [teamA, teamB].includes(match.teams[1].team.abbreviation.toLowerCase())
      })
      return matchstats
    },
  },
  mounted() {
    this.getMatchStats()
  },
  methods: {
    ...mapActions(['getMatchStats']),
    getPageTitle(matchstats) {
      return matchstats[0].teams[0].team.abbreviation + ' vs ' + matchstats[0].teams[1].team.abbreviation 
        + ' - ' + moment(new Date(matchstats[0].matchDate.seconds * 1000)).format('MM/DD/YYYY')
    },
    breadcrumbs(matchstats) {
      let items = [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'IPL',
          disabled: true,
          href: '#',
        },{
          text: moment(new Date(matchstats[0].matchDate.seconds * 1000)).format('MM/DD/YYYY'),
          disabled: true,
          href: '#',
        }
      ]
      return items
    }
  }
}
</script>