<template lang="pug">
div
  div
    v-tabs(v-model='tab' centered)
      v-tabs-slider(color='blue')
      v-tab(v-for='(schedule, index) in schedules' :key="index")
        | {{ toDateString(schedule) }}
    v-tabs-items(v-model='tab')
      v-tab-item(v-for='(schedule, index) in schedules' :key="index")
        MatchStats(:matchstats="getMatchstatsBySchedule(schedule)")
    v-container.text-right(v-if='schedules.length > 0')
      router-link.text-decoration-none.mr-2(to="matches")
        span.text-caption All Matches
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MatchStats from '@/components/MatchStats'
import moment from 'moment'
export default {
  name: 'Statistics',
  metaInfo() {
    return { 
      title: 'Statistics | Find IPL Cricket Prediction Online | CricKrack',
        meta: [
          { name: 'description', content: 'Statistics, Prediction for IPL 2021 at CricKrack.'},
          { property: 'og:title', content: 'Statistics | | Find IPL Cricket Prediction Online | CricKrack'},
          { property: 'og:site_name', content: 'CricKrack'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'index,follow'} 
        ]
    }
  },
  components: {
    MatchStats
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['recentPlayedMatchStats', 'matches']),
    schedules() {
      let matches = this.matches
      let dates = []
      matches = matches.filter(match => match.stats_available)
      matches.forEach(match => dates.push(new Date(match.date.seconds * 1000)))
      dates.sort((a, b) => a.getTime() - b.getTime())
      return dates
        .map(function (date) { return date.getTime() })
        .filter(function (date, i, array) {
            return array.indexOf(date) === i;
        }).slice(-10)
    },
    tab: {
      get() {
        if(parseInt(this.$route.query.tab) < this.schedules.length) {
          return parseInt(this.$route.query.tab)
        }
        return this.schedules.length
      },
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      }
    }
  },
  mounted() {
    this.getRecentPlayedMatchStats()
    this.getMatches()
  },
  watch: {
    schedules(value) {
      this.tab = Math.max(0, value.length - 1)
    }
  },
  methods: {
    ...mapActions(['getRecentPlayedMatchStats', 'getMatches']),
    toDateString(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    getMatchstatsBySchedule(date) {
      let matchstats = this.recentPlayedMatchStats
      matchstats = matchstats.filter(match => {
        let mdate = new Date(match.matchDate.seconds * 1000)
        return moment(mdate).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY')
      })
      return matchstats
    }
  }
}
</script>