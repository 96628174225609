<template lang="pug">
div
  div(v-for="player in sortedItems" :key="player.id")
    div(v-if="player.stats.length")
      p.font-weight-medium.mb-0(:class="[$vuetify.breakpoint.xsOnly ? 'fint-size-14' : 'text-subtitle-2']") {{player.fullName}}
      div(v-if="mode.name === 'pstats'" :class="[$vuetify.breakpoint.xsOnly ? 'fint-size-11' : '']")
        div(v-if="playerType === 'allrounder'")
          p.mb-0.font-weight-light {{recentRunsScored(player.stats)}}
          p.mb-0.font-weight-regular {{tr(player.stats)}} runs at {{sr(player.stats)}}% SR
          p.mb-0.font-weight-regular {{wkt(player.stats)}} Wickets at {{econ(player.stats)}} runs/over ({{to(player.stats)}})
        div(v-if="playerType === 'batsman'")
          p.mb-0.font-weight-light {{recentRunsScored(player.stats)}}
          p.mb-0.font-weight-regular {{tr(player.stats)}} runs at {{sr(player.stats)}}% SR   
        div(v-if="playerType === 'bowler'")
          p.mb-0.font-weight-regular {{wkt(player.stats)}} Wickets at {{econ(player.stats)}} runs/over ({{to(player.stats)}})             
      .font-weight-regular(v-else :class="[$vuetify.breakpoint.xsOnly ? 'fint-size-11' : '']")
        div(v-if="playerType === 'allrounder'")
          p.mb-0 {{tr(player.stats)}} runs at {{(tr(player.stats)/tm(player.stats)).toFixed(0)}} Ave
          p.mb-0 {{sr(player.stats)}}% SR | HS ({{hs(player.stats)}})
          p.mb-0 {{wkt(player.stats)}} Wickets at {{econ(player.stats)}} runs/over ({{to(player.stats)}})
        div(v-if="playerType === 'batsman'")
          p.mb-0 {{tr(player.stats)}} runs at {{(tr(player.stats)/tm(player.stats)).toFixed(0)}} Ave
          p.mb-0 {{sr(player.stats)}}% SR | HS ({{hs(player.stats)}})
        div(v-if="playerType === 'bowler'")
          p.mb-0 {{wkt(player.stats)}} Wickets at {{econ(player.stats)}} runs/over ({{to(player.stats)}})
</template>

<script>
import moment from 'moment'
export default {
  name: 'PlayerInfo',
  props: {
    playerStats: {
      requier: true,
      type: Array
    },
    mode: {
      requier: true
    },
    playerType: {
      requier: true
    }
  },
  computed: {
    sortedItems() {
      let sortedItem = []
      sortedItem = this.playerStats.filter(p => p.stats.length > 0)
      if(this.playerType === 'allrounder' && this.mode.name === 'pstats') {
        sortedItem.sort((a, b) => this.tr(b.stats) - this.tr(a.stats))
        return sortedItem.slice(0, Math.min(1, sortedItem.length)) 
      }
      if(this.playerType === 'allrounder' && this.mode.name === 'h2hstats') {
        sortedItem.sort((a, b) => this.wkt(b.stats) - this.wkt(a.stats))
        return sortedItem.slice(0, Math.min(1, sortedItem.length)) 
      }
      if(this.playerType === 'bowler') {
        sortedItem.sort((a, b) => this.wkt(b.stats) - this.wkt(a.stats))
      }
      if(this.playerType === 'batsman') {
        sortedItem.sort((a, b) => this.tr(b.stats) - this.tr(a.stats))
      }
      return sortedItem.slice(0, Math.min(3, sortedItem.length))
    }
  },
  methods: {
    recentRunsScored(stats) {
      let str = ''
      stats.sort((a, b) => {
        return moment(b.matchDate).diff(moment(a.matchDate))
      })
      stats = stats.slice(0, Math.min(stats.length, 3))
      stats.forEach(s => {
        if(s.battingStats) {
          str += '' + s.battingStats.r + ' (' + s.battingStats.b + ') '
        }
      })
      return str
    },
    tr(stats) {
      let tr = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.battingStats) {
            tr += s.battingStats.r
          }
        })
      } else {
        stats.forEach(s => {
          if(s.battingStats) {
            tr += s.battingStats.tr
          }
        })
      }
      return tr
    },
    hs(stats) {
      let hs = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.battingStats) {
            hs = Math.max(hs, s.battingStats.r)
          }
        })
      } else {
        stats.forEach(s => {
          if(s.battingStats) {
            hs = s.battingStats.hs
          }
        })
      }
      return hs
    },
    sr(stats) {
      let sr = 0, tr = 0, tb = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.battingStats) {
            tr += s.battingStats.r
            tb += s.battingStats.b
          }
        })
        if(tr > 0)
          sr = (tr/tb * 100).toFixed(2)
      } else {
        stats.forEach(s => {
          if(s.battingStats) {
            sr = s.battingStats.sr
          }
        })
      }
      return sr
    },
    wkt(stats) {
      let wkt = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.bowlingStats) {
            wkt += s.bowlingStats.w
          }
        })
      } else {
        stats.forEach(s => {
          if(s.bowlingStats) {
            wkt = s.bowlingStats.w
          }
        })
      }
      return wkt
    },
    econ(stats) {
      let econ = 0.0, tr = 0, to = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.bowlingStats) {
            tr += s.bowlingStats.r
            to += parseInt(s.bowlingStats.ov)
          }
        })
        if(tr > 0)
          econ = (tr/to).toFixed(1)
      } else {
        stats.forEach(s => {
          if(s.bowlingStats) {
            econ = s.bowlingStats.econ
          }
        })
      }
      return econ
    },
    to(stats) {
      let to = 0
      if(this.mode.name === 'pstats') {
        stats.forEach(s => {
          if(s.bowlingStats) {
            to += parseInt(s.bowlingStats.ov)
          }
        })
      } else {
        stats.forEach(s => {
          if(s.bowlingStats) {
            to = s.bowlingStats.to
          }
        })
      }
      return to
    },
    tm(stats) {
      let tm = 0
      if(this.mode.name === 'pstats') {
        tm = stats.length
      } else {
        stats.forEach(s => {
          if(s.battingStats) {
            tm = s.battingStats.tm
          }
        })
      }
      return tm
    }
  }
}
</script>

<style lang="scss" scoped>
.fint-size-14 {
  font-size: 14px;
}

.fint-size-11 {
  font-size: 11px;
}
</style>