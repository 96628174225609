<template lang="pug">
.grey.lighten-3
  .d-flex
    v-card.pa-3(outlined :width="$vuetify.breakpoint.xsOnly ? '43%' : '40%'")
      slot(name="team1")
    v-card.pa-3.text-center(outlined :width="$vuetify.breakpoint.xsOnly ? '14%' : '20%'")
      slot(name="title")
    v-card.text-right.pa-3(outlined :width="$vuetify.breakpoint.xsOnly ? '43%' : '40%'")
      slot(name="team2")
</template>

<script>
export default {
  name: 'InfoItem'
}
</script>


<style lang="scss" scoped>
.v-sheet.v-card {
  border-left: none;
  border-right: none;
}
</style>