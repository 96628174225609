<template lang="pug">
  div(v-if='this.leaderboard[0]')
    v-tabs(v-model='tab' centered)
      v-tabs-slider(color='blue')
      v-tab(v-for='item in items' :key='item')
        | {{ item }}
    v-tabs-items(v-model='tab')
      v-tab-item(v-for='item in items' :key='item')
          v-data-table(v-if="item == 'Team'" :headers='teams_headers' :items='teams' :sort-by="['points', 'nrr']" :sort-desc="[true, true]" :mobile-breakpoint="0" hide-default-footer)
            template(v-slot:item.form="{ item }")
              .d-flex
                v-avatar.ml-1(v-for='(r, index) in item.form.split(",")' :key="index" :color="r == 'L'? 'red': 'green'" size="25")
                  span(class="white--text") {{r}}
            template(v-slot:item.points="{ item }")
              span.font-weight-bold {{item.points}}
          v-data-table(v-if="item == 'Batting'" :headers='batting_headers' :items='batting' :sort-by="['r']" :sort-desc="[true]" :mobile-breakpoint="0" hide-default-footer)
          v-data-table(v-if="item == 'Bowling'" :headers='bowling_headers' :items='bowling' :sort-by="['w']" :sort-desc="[true]" :mobile-breakpoint="0" hide-default-footer)
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Leaderboard',
  metaInfo() {
    return { 
      title: 'Leaderboard | CricKrack',
        meta: [
          { name: 'description', content: 'Leaderboard for IPL 2021 at CricKrack.'},
          { property: 'og:title', content: 'Leaderboard | CricKrack'},
          { property: 'og:site_name', content: 'CricKrack'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'index,follow'} 
        ]
    }
  },
  data () {
    return {
      "items": ["Team", "Batting", "Bowling"],
      "teams_headers": [
        {
          text: 'Team',
          align: 'start',
          value: 'abbreviation',
        },
        { text: 'Pts', value: 'points' },
        { text: 'Pld', value: 'played' },
        { text: 'W', value: 'win', align: ' d-none d-sm-table-cell' },
        { text: 'L', value: 'lost', align: ' d-none d-sm-table-cell' },
        { text: 'Draw', value: 'draw', align: ' d-none d-md-table-cell'},
        { text: 'N/R', value: 'nrr' },
        { text: 'For', value: 'for', align: ' d-none d-md-table-cell' },
        { text: 'Aganist', value: 'aganist', align: ' d-none d-md-table-cell' },
        { text: 'Form', value: 'form' },
      ],
      "batting_headers": [
        {
          text: 'Player',
          align: 'start',
          value: 'shortName',
        },
        { text: 'Team', value: 'team_abbr' },
        { text: 'Mat', value: 'm' },
        { text: 'Balls', value: 'b' },
        { text: 'Runs', value: 'r' },
        { text: 'HS', value: 'hs' },
        { text: '4s', value: '4s', align: ' d-none d-sm-table-cell' },
        { text: '6s', value: '6s', align: ' d-none d-sm-table-cell' },
        { text: 'SR', value: 'sr' }
      ], 
      "bowling_headers": [
        {
          text: 'Player',
          align: 'start',
          value: 'shortName',
        },
        { text: 'Team', value: 'team_abbr' },
        { text: 'Mat', value: 'm' },
        { text: 'Wkts', value: 'w' },
        { text: 'Ov', value: 'ov' },
        { text: 'Runs', value: 'r' },
        { text: 'Econ', value: 'econ' },
        { text: 'Wide', value: 'wd', align: ' d-none d-sm-table-cell' },
        { text: 'NB', value: 'nb', align: ' d-none d-sm-table-cell' } 
      ]
    }
  },
  mounted() {
    this.getLeaderboard()
  },
  computed: {
    ...mapState(['leaderboard']),
    teams() {
      return this.leaderboard[0].teams
    },
    batting() {
     if (this.leaderboard[0]) {
        let batting = this.leaderboard[0].batting.map(i => i).sort((item1, item2) => item2.r - item1.r)
        return batting.splice(0, Math.min(10, batting.length))
      }
      return []
    },
    bowling() {
      if (this.leaderboard[0]) {
        let bowling = this.leaderboard[0].bowling.map(i => i).sort((item1, item2) => item2.w - item1.w)
        return bowling.splice(0, Math.min(10, bowling.length))
      }
      return []
    },
    tab: {
      get() {
        if([0, 1, 2].includes(parseInt(this.$route.query.tab))) {
          return parseInt(this.$route.query.tab)
        }
        return 0
      },
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      }
    }
  },
  methods: {
    ...mapActions(['getLeaderboard'])
  }
}
</script>