<template lang="pug">
nav
  v-app-bar(:color="barColor" dark flat)
    v-app-bar-nav-icon(@click.stop='drawer = !drawer')
    v-spacer
    v-toolbar-title.pl-0
      .d-flex
        v-img.mx-auto(:src="require('../assets/logo-ck.png')" alt="Logo" max-width="40")
        span.font-weight-light.mt-4 Cric
        span.mt-4 Krack
    v-spacer
    v-btn#mode-switcher(icon='' @click='$vuetify.theme.dark = !$vuetify.theme.dark')
      v-icon(:color="iconColor")
        | {{ ($vuetify.theme.dark) ? &apos;mdi-weather-night&apos; : &apos;mdi-weather-sunny&apos; }} 
  v-navigation-drawer(v-model='drawer' absolute='' temporary='')
    v-list(nav='' dense='')
      v-list-item-group(v-model='group' :active-class="activeMenu")
        v-list-item(v-for='item in items' :key='item.title' :to='item.route')
          v-list-item-icon
            v-icon {{item.icon}}
          v-list-item-content
            v-list-item-title {{item.title}}
        v-list-item(href='https://crickrackerz.blogspot.com/' target="_blank")
          v-list-item-icon
            v-icon mdi-creation
          v-list-item-content
            v-list-item-title Blog
      v-list-group(prepend-icon='mdi-palette' value='true')
        template(v-slot:activator='')
          v-list-item
            v-list-item-title Theme
        v-list-item-group
          v-list-item
              v-list-item-title
                v-btn-toggle(v-model="toggle_exclusive" dense group)
                  v-btn(v-for='color in themeColors' :key='color' @click="changeTheme(color)")
                    v-avatar(:color="color" size="20")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { themeColors } from '@/constants'
export default {
  name: 'Header',
  data() {
    return {
      toggle_exclusive: undefined,
      drawer: false,
      group: null,
      items: [
        {title:'Home', icon: 'mdi-home', route:'/'},
        {title:'Leaderboard', icon: 'mdi-account-group-outline', route:'/leaderboard'},
        {title:'Statistics', icon: 'mdi-poll', route:'/statistics'},
        {title:'About Us', icon: 'mdi-information', route:'/about-us'}
      ],
      themeColors: themeColors
    }
  },
  computed: {
    ...mapState(['themeColor']),
    iconColor() {
      return this.$vuetify.theme.dark ? this.themeColor + ' accent-4' : this.themeColor + ' lighten-4'
    },
    barColor() {
      return this.$vuetify.theme.dark ? this.themeColor + ' darken-4' : this.themeColor + ' accent-4'
    },
    activeMenu() {
      return this.themeColor + '--text text--accent-4'
    }
  },
  methods: {
    ...mapActions(['updateThemeColor']),
    changeTheme(color) {
      this.updateThemeColor(color)
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>